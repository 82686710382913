import * as React from 'react';
import * as TOML from '@iarna/toml';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import { Label, Segment } from 'semantic-ui-react';
import { renderMarkdown } from '../../templates/utils';
import { H2, H3 } from '../mdx_blocks/header';

import { sortVersionNumbers } from '../../utils';

/// React hook to query the changelog.
const getChangelog = () => {
  const q = useStaticQuery(
    graphql`
      query Query {
        images: allFile(
          filter: {
            sourceInstanceName: { eq: "doc_pages" }
            relativeDirectory: { eq: "updates/changelog_images" }
            extension: { eq: "png" }
          }
        ) {
          edges {
            node {
              name
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
          }
        }
        changelog: allFile(
          filter: {
            sourceInstanceName: { eq: "salvus" }
            base: { eq: "CHANGELOG.toml" }
          }
        ) {
          edges {
            node {
              fields {
                fileContent
              }
            }
          }
        }
      }
    `
  );
  return {
    changelog: q.changelog.edges[0].node.fields.fileContent,
    images: q.images.edges,
  };
};

interface ChangeItemType {
  change_id: string;
  product: string;
  public_change: boolean;
  description: string;
  part_of_stable_api?: boolean;
  breaks_existing_api?: boolean;
}

interface ChangeLogVersionType {
  release_date: string;
  release_message: string;
  changes: [ChangeItemType];
}

interface ChangelogType {
  string: ChangeLogVersionType;
}

const ChangeLogItemContainer = (props: {
  change: ChangeItemType;
  image: Any;
}) => {
  const colorMap: { [index: string]: string } = {
    SalvusCompute: 'yellow',
    SalvusMesh: 'orange',
    SalvusOpt: 'purple',
    SalvusFlow: 'blue',
    SalvusProject: 'olive',
    SalvusModules: 'teal',
  };

  const backgroundColorMap: { [index: string]: string } = {
    SalvusCompute: '#FFC912',
    SalvusMesh: '#E49254',
    SalvusOpt: '#C900C5',
    SalvusFlow: '#0058C8',
    SalvusProject: '#B5CC19',
    SalvusModules: '#00FFF3',
  };

  const { change } = props;

  return (
    <div css={{ paddingTop: '0.75em' }}>
      <Segment
        size="small"
        // Reduce padding a bit to have a more compact display.
        css={{
          padding: '0.5em 1.5em 1.0em 1.5em !important',
          border: '0px !important',
          backgroundColor: `${backgroundColorMap[change.product]}33 !important`,
          overflowX: 'hidden !important',
        }}
      >
        <Label
          color={colorMap[change.product]}
          attached="top"
          css={{ padding: '0.55em 1em !important' }}
        >
          {change.product}
        </Label>
        {change.breaks_existing_api && (
          <Label color="red" size="tiny" horizontal css={{}}>
            API CHANGE
          </Label>
        )}
        {renderMarkdown(change.description)}
        {props.image !== undefined && (
          <GatsbyImage
            style={{
              display: 'block',
              margin: '0 auto',
              boxShadow: '0px 0px 55px 0px rgba(0,0,0,0.25)',
              maxWidth: '600px',
            }}
            image={props.image.gatsbyImageData}
            alt=""
          />
        )}
      </Segment>
    </div>
  );
};

const ChangeLogVersionContainer = (props: {
  version_number: string;
  changelog_version: ChangeLogVersionType;
  index: number;
  images: any[];
}) => {
  const changesToStableFeatures = [];
  const changesToExperimentalFeatures = [];

  // Get all products.
  for (const change of props.changelog_version.changes) {
    if (!change.public_change) {
      continue;
    }
    // The external changelog validation guarantees that these two keys are
    // available if public_change is true.
    if (
      change.breaks_existing_api === undefined ||
      change.part_of_stable_api === undefined
    ) {
      continue;
    }

    if (change.part_of_stable_api) {
      changesToStableFeatures.push(change);
    } else {
      changesToExperimentalFeatures.push(change);
    }
  }

  // Sort both by product.
  changesToStableFeatures.sort((a, b) => a.product.localeCompare(b.product));
  changesToExperimentalFeatures.sort((a, b) =>
    a.product.localeCompare(b.product)
  );

  const getImage = (name: string) => {
    const allImages = props.images.filter((i) => {
      return (
        i.node.name == `${props.version_number.replace(/\./g, '-')}-${name}`
      );
    });

    if (allImages.length == 0) {
      return undefined;
    }

    return allImages[0].node.childImageSharp;
  };

  return (
    <div>
      {/* Increase padding for all but the first. */}
      <H2 css={props.index != 0 && { paddingTop: '2.5em !important' }}>
        Salvus version {props.version_number}
      </H2>
      <div css={{ paddingBottom: '1em', fontSize: '85%' }}>
        <i>Released: {props.changelog_version.release_date}</i>
      </div>
      <div>{renderMarkdown(props.changelog_version.release_message)}</div>
      {changesToStableFeatures.map((item, index) => (
        <ChangeLogItemContainer
          key={index}
          change={item}
          image={getImage(item.change_id)}
        />
      ))}
      {changesToExperimentalFeatures.length !== 0 && (
        <>
          <H3 css={{ paddingTop: '1.5em !important' }}>
            Changes to Experimental Features in {props.version_number}
          </H3>
          {changesToExperimentalFeatures.map((item, index) => (
            <ChangeLogItemContainer
              key={index}
              change={item}
              image={getImage(item.change_id)}
            />
          ))}
        </>
      )}
    </div>
  );
};

const ChangelogContainer = (props: {
  changelog: ChangelogType;
  images: Any;
}) => {
  // Sort the version numbers in the changelog by abusing existing
  // functionality.
  const v = sortVersionNumbers(
    Object.keys(props.changelog)
      // Get rid of the older changelogs.
      .filter((i) => i != 'older_changelogs')
      // And any unreleased versions.
      .filter((i) => props.changelog[i].release_date != 'unreleased')
  );
  const versionNumbers = [v.current];
  for (let n of v.others) {
    versionNumbers.push(n);
  }
  return (
    <div>
      {versionNumbers.map((item, index) => (
        <ChangeLogVersionContainer
          key={index}
          index={index}
          version_number={item}
          changelog_version={props.changelog[item]}
          images={props.images.filter((i) =>
            i.node.name.startsWith(item.replace(/\./g, '-'))
          )}
        />
      ))}
      <H2>Changelog for Older Versions</H2>
      {renderMarkdown(
        props.changelog['older_changelogs']['public_older_changelog_contents']
      )}
    </div>
  );
};

export function Changelog() {
  const { changelog, images } = getChangelog();
  const parsedChangelog = TOML.parse(changelog);
  return <ChangelogContainer changelog={parsedChangelog} images={images} />;
}
